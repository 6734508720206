import React from "react";
import { Routes, Route } from "react-router-dom";
import usePageTracking from "./hooks/usePageTracking";
import Home from "./containers/Home";
// import Addfedback from "./containers/Addfedback/Addfedback"; // Uncomment if needed

const App = () => {
  // Use the custom page tracking hook
  usePageTracking();

  return (
    <div className="app">
      <Routes>
        <Route path="/" element={<Home />} />
        {/* <Route path="/fedback" element={<Addfedback />} /> */}
      </Routes>
    </div>
  );
};

export default App;
